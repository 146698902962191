import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split  
} from "@apollo/client"
import { createUploadLink } from 'apollo-upload-client'
// import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from "apollo-link-ws";
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { store } from "../redux/storeConfig/store";
import { performLogout } from "../redux/actions/auth/logout";


const {
  REACT_APP_GQL_URI,
  REACT_APP_GQL_BACKEND_ENDPOINT,
  REACT_APP_INCIDENTS_GQL_URI,
  NODE_ENV
} = process.env



const errorLink = onError(({ graphQLErrors, networkError }) => {
  
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (extensions?.code === 'UNAUTHENTICATED') {
        performLogout(store.dispatch);
        window.location.href = '/login';
      }
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('accessTokenV2'); 
  const app = localStorage.getItem('app');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      app: app ? app : "",
      accessToken: token ? token : '',
    }
  }
})


const httpLink = createHttpLink({
  uri: REACT_APP_GQL_URI,
});

const incidentsHttpLink = createUploadLink({
  uri: REACT_APP_INCIDENTS_GQL_URI,
  headers: {
    "apollo-require-preflight": true
  }
});



const wsLink = new WebSocketLink({
  uri: REACT_APP_GQL_BACKEND_ENDPOINT,
  options: {
    reconnect: true
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  split(
    ({ operationName }) => operationName && operationName.startsWith('IncidentGql'),
    authLink.concat(incidentsHttpLink),
    authLink.concat(httpLink)
  )
);

const client = new ApolloClient({  
  link: errorLink.concat(splitLink),
  cache: new InMemoryCache(),      
})


export default client