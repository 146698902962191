import { gql } from "@apollo/client"

export const LOGIN_MUTATION = gql`
  mutation Login($input: LoginUserInput!){
    login(loginUserInput: $input) {
      accessToken
      user{
        _id
        firstName
        lastName
        email
        access {
          _id
          name
          icon
          role {
            _id
            app
            name
            analyticsPermission
            global
          }
        }
      }
    }
  }
`

export const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout {
      message
    }
  }
`