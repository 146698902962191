import { useMutation } from '@apollo/client';

import { useDispatch } from 'react-redux';
import useJwt from '@src/auth/jwt/useJwt'
import { LOGOUT_MUTATION } from '../../../app/login/store/Mutation';

const config = useJwt.jwtConfig

export const useHandleLogout = () => {
  const dispatch = useDispatch();
  const [logout] = useMutation(LOGOUT_MUTATION);

  const handleLogout = async () => {
    console.log("handleLogout");
    await performLogout(dispatch, logout)
  };

  return handleLogout;
};

export const performLogout = async (dispatch, logout) => {
  try {
    if(logout) {
      const { data } = await logout();
    }

    dispatch({ 
      type: 'LOGOUT', 
      [config.storageTokenKeyName]: null, 
      [config.storageRefreshTokenKeyName]: null 
    });

    // Limpiar localStorage
    localStorage.removeItem('userData');
    localStorage.removeItem('playground');
    localStorage.removeItem('visitSelected');
    localStorage.removeItem(config.storageTokenKeyName);
    localStorage.removeItem(config.storageRefreshTokenKeyName);
    localStorage.removeItem("app");
    localStorage.removeItem("appicon");

  } catch (error) {
    console.error("Error en logout:", error);
  }
}
